.faucet-dropdown > button {
  border: none;
  box-shadow: inset 0 0 5px grey;
  -moz-box-shadow: inset 0 0 5px grey;
  -webkit-box-shadow: inset 0 0 5px grey;
  /* border-top-t-radius: 10px !important;
  border-bottom-left-radius: 10px !important; */
  background-color: #010005 !important;
  color: white !important;
  padding: 8px;
}
.progress-bar {
  background-color: #222 !important;
}
.progress {
  height: 0.5rem !important;
  background-color: #d3d3d399 !important;
}
.give-me-trm {
  border: none;
  box-shadow: inset 0 0 5px grey;
  -moz-box-shadow: inset 0 0 5px grey;
  -webkit-box-shadow: inset 0 0 5px grey;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  background-color: #010005 !important;
  padding: 10px;
}
