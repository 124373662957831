/* main div */
.main-bg-pic {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
  /* background: url("./images/background.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
/* Navbar */
.nav123 {
  background-color: #477c17;
  margin-bottom: 20px;
  height: 100px;
}

.gradient-main {
  background: linear-gradient(270deg, #5a8c28 6.34%, #bb9114 84.51%);
}

.text-gradient-main {
  font-size: 4em;
  font-weight: 600;
  text-align: center;
  background: -webkit-linear-gradient(270deg, #5a8c28 6.34%, #bb9114 84.51%);
  background-clip: text;
  -webkit-background-clip: text;
  /* color: transparent; */
  -webkit-text-fill-color: transparent;
}

.bg-footer {
  background-color: #477c17;
}
.nav-link {
  padding: 8px 17px !important;
}
.navbar-nav {
  /* width: 80%; */
  justify-content: space-evenly;
}
.navbar-drop > button {
  background: transparent !important;
  border: none !important;
  width: 120px;
  font-weight: 600;
}
.navbar-drop > button::after {
  margin-top: 11px !important;
  padding-top: 0px !important;
  color: #d9ab40 !important;
}

.bg-navdrop-items {
  background-color: #d9ab40 !important;
  border: 1px solid grey;
  margin-top: 2px;
}
.dropdown-item {
  padding: 0px !important;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.cardHome123 {
  border-radius: 15px !important;
  background: linear-gradient(270deg, #5a8c28 6.34%, #bb9114 84.51%);
  /* border: 3px solid #495d32; */
  min-height: 100px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.cardHome123 .formPart {
  margin: 10px 10px !important;
  min-width: 100px;
}
.cardHome123 .formPart-btn {
  margin: 10px 10px !important;
  margin-top: 0px !important;
  max-width: 300px;
}
.cardHome123 .row {
  width: 100% !important;
}
.btn-gold-gradient {
  background: #c7c2c2;
  color: black;
  font-weight: 600;
  font-size: large;
  max-width: 200px;
}

.btn-home {
  padding: 10px;
  border-radius: 10px 0;
  background: #fefefe;
  color: #477c17;
  font-weight: 700;
}

.btn-gold-gradient:hover {
  background: transparent;
}

.txList {
  font-weight: 600;
  border-bottom: 2px solid #fafafa;
  margin: 5px 0px !important;
  padding: 15px 0px;
}
.timeSec p {
  margin-bottom: 5px;
}

/* Home */
.input-bg-form {
  border: none;
  box-shadow: inset 0 0 5px grey;
  -moz-box-shadow: inset 0 0 5px grey;
  -webkit-box-shadow: inset 0 0 5px grey;
  /* border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important; */
  background-color: #010005;
  color: white;
  padding: 12px !important;
}
.input-bg-form::placeholder {
  color: grey;
}
.input-bg-form:focus {
  border: none;
  box-shadow: inset 0 0 5px grey;
  -moz-box-shadow: inset 0 0 5px grey;
  -webkit-box-shadow: inset 0 0 5px grey;
  /* border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important; */
  background-color: #010005;
  color: white;
}
.input-bg-form-search {
  border: none;
  box-shadow: inset 0 0 5px grey;
  -moz-box-shadow: inset 0 0 5px grey;
  -webkit-box-shadow: inset 0 0 5px grey;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  background-color: #010005;
  color: white;
}
.homefilter-drop > button,
.homefilter-drop > button:hover,
.homefilter-drop > button:active {
  border: none;
  box-shadow: inset 0 0 5px grey;
  -moz-box-shadow: inset 0 0 5px grey;
  -webkit-box-shadow: inset 0 0 5px grey;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  background-color: #010005 !important;
  color: white !important;
  padding: 12px !important;
}
.homefilter-drop-items {
  border: none;
  box-shadow: inset 0 0 5px grey;
  -moz-box-shadow: inset 0 0 5px grey;
  -webkit-box-shadow: inset 0 0 5px grey;
  border-radius: 10px !important;
  background-color: #010005 !important;
  color: white !important;
  padding: 6px 0px !important;
}
.homefilter-drop-ind-items:hover,
.bg-navdrop-ind-items:hover {
  background-color: transparent !important;
}
.gold-color {
  color: #d9ab40;
}
.background-black {
  border: none;
  box-shadow: inset 0 0 5px grey;
  -moz-box-shadow: inset 0 0 5px grey;
  -webkit-box-shadow: inset 0 0 5px grey;
  border-radius: 10px !important;
  background-color: #010005a3 !important;
}

/* footer */
.footer-div {
  display: flex;
  justify-content: space-between;
}
.footer-last-section-width {
  width: 60%;
}
@media (max-width: 922px) {
  .footer-div {
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 922px) {
  .footer-last-section-width {
    width: 80%;
  }
}

/* graph */
#apexchartsapexchartxexample,
#SvgjsSvg1001 {
  background: transparent !important;
}
.apexcharts-toolbar {
  display: none !important;
}
