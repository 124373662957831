body {
  margin: 0;
  font-family: "Jost", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-left {
  z-index: -1;
  top: 0%;
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("./images/bgleft.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  /* opacity: 10%; */
}

.bg-right {
  z-index: -1;
  top: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("./images/bgright.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
}
